/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';
import { SupportedLocale } from '@sprinx/knihovna-types';
import ExpandIcon from 'icons/ExpandIcon';
import { FC, useCallback, useState } from 'react';
import { LocaleSwitchHrefs } from 'types/LocaleSwitchHrefs';

type LangSwitcherProps = {
  locale: SupportedLocale;
  localeHrefs?: LocaleSwitchHrefs;
};

const LangSwitcher: FC<LangSwitcherProps> = ({ locale, localeHrefs }) => {
  const [showMenu, setShowMenu] = useState(false);
  const handleShow = useCallback(() => setShowMenu(true), [setShowMenu]);
  return localeHrefs ? (
    <div
      css={() => ({
        alignItems: 'center',
        color: 'black',
        display: 'flex',
        fontWeight: 'bold',
        padding: 8,
        position: 'relative',
        top: -4,
      })}
    >
      <button
        onClick={handleShow}
        css={{ background: '#FFF', border: 'none', fontSize: 18, textTransform: 'uppercase' }}
      >
        {locale} <ExpandIcon css={{ bottom: -5, position: 'relative' }} />
      </button>
      {showMenu && (
        <div
          css={{
            background: '#FFF',
            border: '1px solid rgba(0, 0, 0, 0.12);',
            borderTop: 'none',
            bottom: -40,
            left: -7,
            padding: 15,
            paddingRight: 15,
            position: 'absolute',
            width: 80,
            zIndex: 999,
          }}
        >
          {Object.entries(localeHrefs).map(([lc, st]) => (
            <div key={lc}>
              {lc === locale ? null : (
                <a css={() => ({ color: 'black', fontWeight: 400, textDecoration: 'none' })} href={st.href}>
                  {st.label}
                </a>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  ) : null;
};

export default LangSwitcher;
