import Page, { PageHeadline } from 'components/Page';
import MainLayout from 'layouts/MainLayout';
import React from 'react';
import { Link as RouterLink, Route } from 'react-router-dom';
import { routeUrl } from 'routesBuilder';
import getNavigation from 'server/layout';

export interface ErrorPageProps {
  error?: string | Error;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ error }) => {
  const navigation = getNavigation('en');
  const layout = {
    header: {
      navigation: navigation,
    },
  };
  if (process.env.NODE_ENV !== 'production') {
    if (error) {
      if (error instanceof Error) {
        console.error(error.message, error);
      } else {
        console.error('Internal error:', error);
      }
    }
  }

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 500;
        return (
          <MainLayout {...layout}>
            <Page pageTitle='Error occurred...'>
              <PageHeadline>An error occurred...</PageHeadline>
              <div>
                <RouterLink to={routeUrl('home', { locale: 'en', params: {} })}>BACK TO HOME</RouterLink>
              </div>
            </Page>
          </MainLayout>
        );
      }}
    />
  );
};

ErrorPage.displayName = 'ErrorPage';

export default ErrorPage;
