/* eslint-disable max-len */
const messages = {
  en: {
    'aboutStores.numberOfStores': 'Number of our stores',
    'brands.goToWebsite': 'Go to the website',
    'brands.title': 'Our own brands and portfolio',
    'contact.headquarters': 'Headquarters',
    'cookieconsent.text':
      'Cookies help us deliver our services. By using our services, you agree to our use of cookies.',
    'downloadsPage.filesForDownload': 'Files for download',
    'downloadsPage.form': 'Form',
    'footer.contacts': 'Contacts',
    'footer.ourBrands': 'Our Brands',
    'footer.ourCompany': 'Our Company',
    'footer.policy': 'Privacy and Cookies policy',
    'navigation.contactUs': 'Contact us',
    'newsletter.placeholder': 'enter your email',
    'newsletter.subscribe': 'subscribe',
  },
};

export default messages;
