/*
 * !!! This is only example / skeleton of an remote resource.
 * !!! Have to create implementation.
 */
import { CancelablePromiseType } from '@sprinx/after-razzle';
import apiClient from './appApi';

export default function subscribeToNewsletter(params: { email: string; path: string }): CancelablePromiseType<string> {
  return apiClient.post<string, { email: string; path: string }>('subcribe-to-newsletter', params);
}
