/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';

const Logo: React.FC<{ className?: string }> = ({ className, ...rest }) => {
  return (
    <ClassNames>
      {({ css, cx }) => (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          version='1.1'
          id='Placek_logo'
          x='0px'
          y='0px'
          viewBox='0 0 194.74 78.77'
          className={cx(
            css({
              width: 155,
            }),
            className,
          )}
          {...rest}
        >
          <g>
            <path
              d='M44.78,19.82c5.31,0,7.47,0.44,8.93,1.72c1.28,1.15,1.94,2.88,1.94,5.04c0,3.45-1.59,7.56-3.98,10.3   c-1.64,1.86-4.11,3.27-6.63,3.8c-1.72,0.35-3.45,0.44-8,0.44h-2.25l-1.94,8.18H20.99l7.08-29.49H44.78z M36.47,34.23h0.66   c2.56,0,3.89-0.22,4.69-0.84c1.15-0.79,1.9-2.34,1.9-3.8c0-1.72-0.88-2.12-4.6-2.12h-1.06L36.47,34.23z M63.58,49.31H52.39   l7.39-30.82h11.23L63.58,49.31z M95.46,49.31H84.27c0.09-0.93,0.71-3.1,1.42-5.04h-0.22c-2.08,4.02-4.42,5.44-9.2,5.44   c-5.13,0-7.78-2.56-7.78-7.6c0-3.76,1.33-9.2,3.1-12.47c2.25-4.29,5.48-6.19,10.39-6.19c4.91,0,6.98,1.68,6.98,5.66v0.62h0.22   l0.53-2.92c0.22-1.33,0.26-1.59,0.53-2.39h11.19L95.46,49.31z M80.03,39.32c0,1.72,0.93,2.61,2.74,2.61c1.68,0,2.96-0.84,3.76-2.48   c0.62-1.33,1.06-2.96,1.15-4.29c0.09-1.55-0.97-2.43-2.87-2.43C81.97,32.73,80.03,35.43,80.03,39.32z M124.65,34.59   c-2.57-0.75-5.08-1.2-7.03-1.2c-2.3,0-3.76,0.58-4.78,1.81c-0.71,0.93-1.15,2.12-1.15,3.32c0,2.12,1.37,3.01,4.73,3.01   c2.39,0,4.6-0.31,6.81-0.88l-1.77,7.3c-3.27,1.5-5.97,2.08-10.04,2.08c-7.6,0-11.1-2.78-11.1-8.93c0-3.94,1.19-8.31,3.1-11.36   c2.74-4.29,6.81-6.28,13.09-6.28c4.02,0,6.72,0.57,10.35,2.08L124.65,34.59z M110.41,15.84h5.93l1.24,3.23l2.96-3.23h6.32   l-5.08,5.75h-9.02L110.41,15.84z M136.28,39.89c-0.04,0.31-0.04,0.53-0.04,0.8c0,1.95,1.37,2.65,5.31,2.65   c2.39,0,5.35-0.4,9.68-1.37l-2.79,6.54c-4.33,1.19-7.91,1.72-11.85,1.72c-7.52,0-11.1-2.79-11.1-8.71c0-5.44,2.12-11.41,5.17-14.46   c2.43-2.47,6.15-3.63,11.63-3.63c8.18,0,11.63,2.3,11.63,7.78c0,2.43-0.49,4.6-1.86,8.67H136.28z M144.9,34.19   c0.09-0.53,0.13-0.84,0.13-1.19c0-1.63-1.1-2.48-3.23-2.48c-2.43,0-4.11,1.37-4.6,3.67H144.9z M176.82,36.89l3.54,12.42h-11.32   l-1.59-5.97c-0.53-2.04-0.66-2.74-0.97-4.82h-0.31c-0.09,0.97-0.53,3.4-0.84,4.82l-1.46,5.97h-11.19l7.38-30.82h11.23l-2.61,10.83   c-0.09,0.27-0.35,1.46-0.88,3.49c-0.22,0.8-0.44,1.5-0.93,2.65h0.18c0.22-0.44,0.31-0.58,0.84-1.24c0.84-1.24,1.19-1.68,1.28-1.81   l5.7-8h11.98L176.82,36.89z'
              css={cx(
                css({
                  fill: 'rgb(255, 255, 255)',
                }),
                className,
              )}
            ></path>
            <path
              d='M59.24,54.07c-5.36,6.11-13.22,9.98-21.98,9.98c-16.14,0-29.23-13.09-29.23-29.23   c0-16.14,13.09-29.23,29.23-29.23c7.18,0,13.75,2.59,18.83,6.88h2.51C52.69,6.24,44.33,2.35,35.07,2.35   C17.14,2.35,2.6,16.89,2.6,34.82c0,17.93,14.53,32.46,32.46,32.46c10.72,0,20.22-5.2,26.13-13.21H59.24z'
              css={cx(
                css({
                  fill: 'rgb(255, 255, 255)',
                }),
                className,
              )}
            ></path>
          </g>
        </svg>
      )}
    </ClassNames>
  );
};

export default Logo;
