/* eslint-disable max-len */
const messages = {
  cs: {
    'aboutStores.numberOfStores': 'Počet našich obchodů',
    'brands.goToWebsite': 'Přejít na web',
    'brands.title': 'Naše vlastní značky a portfolio',
    'contact.headquarters': 'Centrála',
    'cookieconsent.text':
      'Cookies nám pomáhají poskytovat naše služby. Používáním našich služeb souhlasíte s používáním cookies.',
    'downloadsPage.filesForDownload': 'Soubory ke stažení',
    'downloadsPage.form': 'Formulář',
    'footer.contacts': 'Kontakty',
    'footer.ourBrands': 'Naše značky',
    'footer.ourCompany': 'Naše společnost',
    'footer.policy': 'Ochrana osobních údajů a politika cookies',
    'navigation.contactUs': 'Kontaktujte nás',
    'newsletter.placeholder': 'zadejte email',
    'newsletter.subscribe': 'souhlasím',
  },
};

export default messages;
