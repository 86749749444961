import { ApiClient, ApiClientClass } from '@sprinx/after-razzle/apiClient';
import invariant from 'tiny-invariant';

const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

const appApi: ApiClient = (() => {
  const baseURL = canUseDOM ? process.env.RAZZLE_API_URI : process.env.RAZZLE_API_URI_SSR || process.env.RAZZLE_API_URI;
  const secureClientId = process.env.RAZZLE_AUTH_CLIENT_ID;

  invariant(
    baseURL,
    [
      'Can not create instance of application API client.',
      "It seems that environment variable 'RAZZLE_API_URI' is not set.",
      "Set it in '.env' file or in command line.",
    ].join(' '),
  );

  // invariant(secureClientId, [
  //   'Can not create instance of application API client.',
  //   "It seems that environment variable 'RAZZLE_AUTH_CLIENT_ID' is not set.",
  //   "Set it in '.env' file or in command line."
  // ].join(' '));

  // Place here other initial settings of application api client.
  return new ApiClientClass({
    baseURL,
    secureClientId,
  });
})();

export default appApi;

//
// If auth users are provided uncomment and use next Recoil Atom Effect in AccessToken atom.
//
// export function atomEffectSetAccessToken(): AtomEffect<string | undefined> {
//   return ({ onSet }) => {
//     onSet((accessToken) => {
//       if (accessToken instanceof DefaultValue || !accessToken) {
//         delete (appApi as ApiClientClass).api.defaults.headers.common['Authorization'];
//       } else {
//         (appApi as ApiClientClass).api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
//       }
//     });
//   };
// }
