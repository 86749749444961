/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { EmotionCache } from '@emotion/cache';
import { CacheProvider, ClassNames, css, Global, jsx, ThemeProvider } from '@emotion/react';
import CalibriBold from 'fonts/Calibri-bold.ttf';
import CalibriLight from 'fonts/Calibri-light.ttf';
import CalibriRegular from 'fonts/Calibri-regular.ttf';
import React from 'react';
import { Helmet } from 'react-helmet';
import theme from 'theme';

export interface AppProps {
  children: React.ReactElement;
  emotionCache: EmotionCache;
}

const App: React.FC<AppProps> = ({ children, emotionCache }) => {
  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <ClassNames>
          {({ theme }) => (
            <>
              <Global
                styles={css`
                  *, html {
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                  }

                  body {
                    @font-face {
                      font-family: Calibri;
                      font-style: normal;
                      font-weight: 500;
                      src: url:(${CalibriRegular});
                    }
                    color: ${theme.palette.text.primary};
                    font-family: ${theme.typography.fontFamily};
                    font-size: 1rem;
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;

                    @font-face {
                      font-family: Calibri;
                      font-style: normal;
                      font-weight: 600;
                      src: url:(${CalibriBold});
                    }

                    @font-face {
                      font-family: Calibri;
                      font-style: normal;
                      font-weight: 400;
                      src: url:(${CalibriLight});
                    }
                  }
                `}
              />
            </>
          )}
        </ClassNames>
        <Helmet titleTemplate='%s - Plaček website' defaultTitle='Plaček website' />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
};

App.displayName = 'App';

export default App;
