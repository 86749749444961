import createCache from '@emotion/cache';
import { After, ensureReady } from '@jaredpalmer/after';
import App from 'App';
import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import routes from 'routes';

ensureReady(routes).then((data: any) =>
  hydrate(
    <BrowserRouter>
      <App emotionCache={createCache({ key: 'app' })}>
        <After data={data} routes={routes} transitionBehavior='blocking' />
      </App>
    </BrowserRouter>,
    document.getElementById('root'),
  ),
);

if (module.hot) {
  module.hot.accept();
}
