/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';

export interface PageHeadlineProps extends React.HTMLAttributes<HTMLDivElement> {
  actions?: React.ReactNode;
  children: React.ReactNode;
}

const PageHeadline = React.forwardRef<HTMLDivElement, PageHeadlineProps>(
  ({ actions, className, children, ...rest }, ref) => {
    //<div ref={ref} className={className} {...rest}>
    return (
      <ClassNames>
        {({ theme, cx, css }) => (
          <h1
            ref={ref}
            {...rest}
            css={cx(
              className,
              css({
                ...theme.typography.h2,
              }),
            )}
          >
            {children}
          </h1>
        )}
      </ClassNames>
    );
  },
);

PageHeadline.displayName = 'PageHeadline';

export default PageHeadline;
