import { SupportedLocale } from '@sprinx/knihovna-types';
import { messagesIntl } from './i18n';

const translate = (locale: SupportedLocale) => (key: keyof typeof messagesIntl['en']): string => {
  const r = messagesIntl[locale][key];

  if (typeof r === 'string') {
    return r;
  } else {
    console.error(`Key ${key} not found`);
    return key;
  }
};

export default translate;
