import getNavigation from 'server/layout';
import Page, { PageHeadline } from 'components/Page';
import MainLayout from 'layouts/MainLayout';
import React from 'react';
import { Link as RouterLink, Route } from 'react-router-dom';
import { routeUrl } from 'routesBuilder';

export type NotFoundPageProps = React.HTMLAttributes<HTMLDivElement>;

const NotFoundPage: React.FC<NotFoundPageProps> = () => {
  const navigation = getNavigation('en');
  const layout = {
    header: {
      navigation: navigation,
    },
  };
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 404;
        return (
          <MainLayout {...layout}>
            <Page pageTitle='Page not found...'>
              <PageHeadline>{'Page not found...'}</PageHeadline>
              <div>
                <RouterLink to={routeUrl('home', { locale: 'en', params: { locale: 'en' } })}>BACK TO HOME</RouterLink>
              </div>
            </Page>
          </MainLayout>
        );
      }}
    />
  );
};

NotFoundPage.displayName = 'NotFoundPage';

export default NotFoundPage;
