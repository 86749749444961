/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { Helmet } from 'react-helmet';

export interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  breadcrumbs?: React.ReactNode;
  children?: React.ReactNode;
  pageTitle: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ breadcrumbs, className, children, pageTitle, ...rest }) => {
  return (
    <div
      className={className}
      {...rest}
      css={{
        '& > div': { alignItems: 'center', display: 'flex', flexDirection: 'column', width: '100%' },
        width: '100%',
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {breadcrumbs}
      <div>{children}</div>
    </div>
  );
};

Page.displayName = 'Page';

export default Page;
