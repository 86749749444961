import ErrorPage from 'pages/ErrorPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import { routeAllRoutesToRouter } from 'routesBuilder';

export default [
  ...routeAllRoutesToRouter(),
  {
    component: ErrorPage,
    exact: true,
    path: '/_error',
  },
  {
    component: NotFoundPage,
  },
];
