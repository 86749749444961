/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, disabled = false, children, type = 'button', ...rest }, ref) => (
    <ClassNames>
      {({ cx, css, theme }) => (
        <button
          disabled={disabled}
          type={type}
          ref={ref}
          className={cx(
            css({
              '&:hover': {
                background: theme.palette.primary.dark,
                boxShadow: theme.shadows[6],
              },
              alignItems: 'center',
              alignSelf: 'center',
              background: theme.palette.primary.main,
              border: 'none',
              borderRadius: '3px',
              boxShadow: theme.shadows[3],
              color: theme.palette.common.white,
              cursor: 'pointer',
              display: 'flex',
              fontSize: '18px',
              justifyContent: 'center',
              outline: 'unset',
              padding: theme.spacing(1, 2),
              textTransform: 'uppercase',
              transition: 'all 0.2s ease',
            }),
            className,
          )}
          {...rest}
        >
          {children}
        </button>
      )}
    </ClassNames>
  ),
);

Button.displayName = 'Button';

export default Button;
